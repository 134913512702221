<template>
  <ui-card
    v-loading="loading"
    :heading="`${$t('actions.create')} ${$tc('models.user')}`"
  >
    <company-user-form
      :user="user"
      @submit="handleSubmit"
      @cancel="handleCancel"
    ></company-user-form>
  </ui-card>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import CompanyUserForm from '../components/CompanyUserForm';
import { createUser } from '../../users/api';
import { getCompany, getCompanyRole } from '../api';

export default {
  data() {
    return {
      user: {
        email: '',
        role: null,
        company: null
      },
      loading: false
    };
  },

  components: {
    UiCard,
    CompanyUserForm
  },

  methods: {
    async getCompany() {
      this.loading = true;
      try {
        this.user.company = await getCompany(
          this.$router.history.current.params.id
        );
        this.user.role = await getCompanyRole();
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    },

    async handleSubmit() {
      this.loading = true;
      try {
        await createUser(this.user);
        this.loading = false;
        this.$router.back();
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.back();
    }
  },

  created() {
    this.getCompany();
  }
};
</script>
